export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.params.deviceUri) {
    const { deviceUri } = to.params;
    const { page = 1 } = to.query;
    const params = {
      page: page as number,
      device_uri_id: deviceUri as string,
      size: 100,
      image_size: "840x840",
    };
    const servicePoints = await getServicePoints(params);

    if (servicePoints.results.length === 0) {
      return navigateTo("/not-found");
    }

    const servicePointsState = useState<PaginatedResult<ServicePoint>>(
      "servicePointsByDeviceUriId",
      () => servicePoints
    );
    servicePointsState.value = servicePoints;
  }
});
